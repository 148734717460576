@import "_vars";
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

body{
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}

*{
    box-sizing: border-box;
}

h1, h2, h3, h4, h5 { color:$mainFontColor }

h1 { 
    text-align: center;
    font-weight: 700;

    &.menor { font-size: 1.9em; }
}

.menor { font-size: 0.9em; }

h2{
    font-size: 1.5rem;
    font-weight: 700;

    &.menor { font-size: 1.2em; }
}

h3{
    font-size: 1.2rem;
    font-weight: 500;
}

h4{
    font-size: 1.1rem;
}

h5{
    font-size: 1rem;
}

label{
    margin-left: 15px;
    color: $supportFontColor !important;
}

input{ 
    color: $mainFontColor !important;
    border-color: $supportFontColor !important;
    border-radius: 6px;

    &:focus { box-shadow: none !important; }
}

strong { font-weight: 500; }

img { 
    max-width: 100%;
    margin: 0;
}

a { 
    display: inline-block;
    width: auto !important;    
    color: $mainColor !important;
    font-weight: 500;
    cursor: pointer;

    &:hover { text-decoration: none; }
    figure:hover { opacity: .9; }
}

footer{
    background-color: darken($mainFontColor, 15%);
    padding: 60px;

    & * { color: $supportFontColor !important; }

    h1, h2, h3, h4, h5 { color: white !important; }
}

// ==========================================
// CLASSES
// ==========================================
.containerMain{
    margin-top: 70px;
    padding-bottom: 50px;
    min-height: calc(100vh - 140px);
}


.title-line{

    display: flex;
    color: $mainFontColor;

    span:first-child{
        position: relative;
        align-self: center;
        display: inline-block;
        width: 2%;
        margin-right: 15px;
        background-color: $mainFontColor;
        height: 1px;
    }

    span:nth-child(2){
        white-space: nowrap;
    }

    span:last-child{
        position: relative;
        align-self: center;
        display: inline-block;
        width: 100%;
        margin-left: 15px;
        background-color: $mainFontColor;
        height: 1px;
    }
}

.subtitle{
    text-align: center;
    font-weight: 400;
    font-size: 1.5rem;
}

.help-text{
    font-size: 0.7rem;
    font-weight: 400;
}

.text-left { text-align: left !important; }

.normal-p{
    font-size: 0.9;
    font-weight: 400;
}

.inative { opacity: .2; }

.btn { 
    font-size: .875em;
    white-space: nowrap;
}

.btn-primary{
    background-color: $mainColor;
    color: white !important;
    font-weight: 400;
    border: none;
    border-radius: 60px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: min(80px, 20vw);
    padding-right: min(80px, 20vw);
    text-transform: uppercase;
    transition: .25s;
    box-shadow: 0px .5rem 10px 0px lighten($mainColor, 20%);

    &:hover, &:focus, &:active{
        background-color: darken($mainColor, 20%);
        box-shadow: 0px .5rem 10px 0px lighten($mainColor, 20%);
        border: none;
    }

    &:disabled{
        background-color: lighten($mainColor, 5%);
        box-shadow: 0px .5rem 10px 0px lighten($mainColor, 20%);
        border: none;
    }

    *{
        color: white !important;
    }

    .spinner-grow{
        width: 21px;
        height: 21px;
    }
}

.form-control {
    height: calc(3.2rem + 3px);
    &:focus { box-shadow: none !important; }
}

.was-validated .form-control:valid { 
    border-color: $supportFontColor  !important;
    background-image: none !important;
}     

.form-check-input {
    width: 1.2em;
    height: 1.2em;
    margin-top: 0.10em;
    border-radius: 4px;
    cursor: pointer;

    &:checked { background-color: #747474 !important; }
}


.container-menu {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 30px;

    .navbar-brand,
    .figure { padding: 0; margin: 0; }
}

#dropdown-menu{
    background-color: transparent;
    border-radius: 0px;
    padding: 0px;
    text-align: center;
    box-shadow: none;
}

.dropdown-menu-container{
    left: auto !important;
    right: 13px !important;
    min-width: 200px;
    padding: 30px 0;
    margin-top: 5px !important;
    border-radius: 10px;
    border: 1px solid #dadada;
    box-shadow: 5px 4px 12px #ededed;

    a{
        font-size: .875em;
        font-weight: 400;
        color: #4a4a4a !important;
        margin: 0 30px 10px 30px !important;
        padding: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;        
        &:last-child { margin-bottom: 0 !important; }

        &:hover { font-weight: 700; }
    }
}

.figure-stores{
    img{
        max-width: 210px;
    }
}

.mainContent {
    max-width: 768px;
    padding: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;        
    margin-bottom: 120px;

    // border: 1px solid red;
}

// Toaster
.Toastify__toast {
    font-family: 'Montserrat', sans-serif;
    font-size: .875em;
    border-radius: 15px;
}
.Toastify__toast-body { padding: 15px; }
.Toastify__toast-container {
    width: auto;
    max-width: 400px;
}
.Toastify__toast-icon { margin-inline-end: 20px; }
.Toastify__toast--warning { background: #4a4a4a !important; }
.Toastify__toast--error { background: #ee3f60 !important; }


.quadro-contratacao{
    padding: 60px 30px;
    width: min(1140px, 100vw);
    box-sizing: border-box;

    & > .row{
        box-sizing: border-box;
        & > div{
            box-sizing: border-box;
        }
    }
}

.container-notFound{
    padding: 30px;
    min-height: 60vh;
    justify-items: center;
    align-items: center;
    position: relative;
    top: max(-10vh, -120px);
    display: grid;

    h1{
        font-size: 2rem;
    }
}

#loading-animation{
    background-color: transparent;
    margin: auto;
    width: min(90vw, 500px);
    position: relative;
    margin-top: 10vh;
}


.menu-principal {
    background-color: #fff !important;
    border-bottom: 1px solid $supportFontColor;
    padding: 15px 0;

    .navbar-toggler { 
        border: 0 !important;
        box-shadow: none;
        padding: 0 !important;
    }

    .menu-right { justify-content:flex-end; }

    .dropdown-divider { margin: 15px 0; }
}

.seguro-item{
    padding: 30px;
    border-radius: 10px;
}

.input-search{
    border: 2px solid $supportFontColor;
    border-radius: 20px;
    background-color: white;

    input{
        // padding-left: 15px;
        background-color: transparent;
        border: none;
        border-radius: 20px 0 0 20px;
    }

    button {
        border: none;
        padding: 0 30px;
        border-radius: 0 20px 20px 0;
        background-color: $mainColor;
        
        svg{
            color:white;
        }

        &:hover{
            background-color: darken($mainColor, 20);
        }

        &:active, &:focus{ 
            border: none;
            box-shadow: none;
        }
    }

    &.disabled{
        background-color: #e9ecef;
    }
}

.seguroCardDetails{
    border: none;
    border-radius: 5px;
    
    
    .card-header{
        border-radius: 5px 5px 0 0 ;
        background-color: $mainColor;
        color: white;
        
        h3{
            color: white;
            text-align: left;
        }
    }
}


// Loading Screen
#loadingScreen{
    position: fixed;
    width: 100vw;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
}

.tag{
    padding: 5px 15px;
    background-color: #ee3f60;
    display: inline-block;
    border-radius: 15px;
    color: white;
}

.policyHistory{
    height: 200px;
}

// ==========================================
// MOBILE
// ==========================================
@media only screen and (max-width: 991px) {
    .menu-right { 
        padding: 20px 0;

        a{
            font-size: .875em;
            font-weight: 400;
            color: #4a4a4a !important;
            margin: 0 0 10px 0 !important;
            padding: 0;
            padding-left: 0 !important;
            padding-right: 0 !important;        
            &:last-child { margin-bottom: 0 !important; }
    
            &:hover { font-weight: 700; }
        }
    }
}

@media only screen and (max-width: 600px) {
    h1 { 
        font-size: 1.5em; 
        &.menor { font-size: 1.4em; }
    } 

    .container-menu { padding: 0 15px; }

    .menu-principal { margin-bottom: 80px; }    

    .quadro-contratacao {
        padding: 60px 30px;
        width: 100vw;
        position: relative;
    }
}

@media only screen and (max-width: 320px) {
    h1 { 
        font-size: 1.3em;
        &.menor { font-size: 1.2em; }
    } 
}


// ==========================================
// HELPERS
// ==========================================
.color-main{ color: $mainColor !important}
.color-mainFont{ color: $mainFontColor !important}
.color-white{ color: white !important}
.color-white-all{ color: white !important; *{color: white !important;}}
.bg-bgColor{ background-color: $bgColor !important }
.bg-main{ background-color: $mainColor !important }
.bg-mainFont{ background-color: $mainFontColor !important }
.bg-white{ background-color: white !important }
.bg-main-button{ 
    button{ 
        background-color: $mainColor !important;
        color: white !important;
        &::after{
            color: white;
            filter: grayscale(100%);
        }
    }

}
.bg-mainFont-button{ 
    button{ 
        background-color: $mainFontColor !important;
        color: white !important;
        &::after{
            color: white;
            filter: grayscale(100%);
        }
    }

}
.container-m{ max-width: min(768px, 90vw); padding: 0 15px; margin: auto;}
.font-medium{ font-weight: 500; }
.font-bold{ font-weight: 700; }

.roundIcon{
    background-color: $mainColor;
    width: 80px;
    height: 80px;
    padding: 15px;
    border-radius: 50%;

    img{
        object-fit: contain;
        object-position: center;
    }

    &.special{
        img{
            width: 83%;
        }
    }
}

.radioButon-dashbord{
    margin: 7px;
    margin-right: 25px;
    label{
        color: darken($supportFontColor, 40%) !important;
    }

}
.search-button{
    margin-top: 30px;
    border: none;
    padding: 1rem;
    border-radius: 22px;
    background-color: $mainColor;

    svg{
        color:white;
    }

    &:hover{
        background-color: darken($mainColor, 20);
    }
}

.btn-danger{
    transform: scale(0.85);
    background-color: $mainColor;
    color: white !important;
    font-weight: 400;
    border: none;
    border-radius: 60px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-transform: uppercase;
    transition: .25s;

    &:hover, &:focus, &:active{
        background-color: darken($mainColor, 20%);
        box-shadow: 0px .5rem 10px 0px lighten($mainColor, 20%);
        border: none;
    }

    &:disabled{
        background-color: lighten($mainColor, 5%);
        box-shadow: 0px .5rem 10px 0px lighten($mainColor, 20%);
        border: none;
    }
}

.div-cards{
    margin-top: 15px;
}

.card-dashbord{
    border: none;
    border-radius: 5px;
    font-weight: bold;
    div{
        color: white;
        border-radius: 5px;
    }

}

.checkbox-filter{
    label{
        color: darken($supportFontColor, 50%) !important;
    }
}

.label-filter{
    color: darken($supportFontColor, 50%) !important;
    text-transform: capitalize !important;
}

.link-button-list{
    margin-top: 30px;
}

.input-select{
    color: $mainFontColor !important;
    border-color: $supportFontColor !important;
    border-radius: 6px;

    &:focus { box-shadow: none !important; }
}

.input-group{
   margin-top: 7px;
}